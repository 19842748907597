document.addEventListener('DOMContentLoaded', async () => {
  const observable = document.querySelectorAll('.js-observable');

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('js-intersecting');
          observer.unobserve(entry.target)
        }
    });
  }, {
    threshold: 0.25
  });

  observable.forEach(node => {
    if (window.scrollY > node.getBoundingClientRect().top) {
      requestAnimationFrame(() => {
        node.classList.add('js-intersecting');
      });
    }

    observer.observe(node)
  });

  const burgerBtn = document.querySelector(".header__burger");
  const mobileMenu = document.querySelector(".mobile-menu");
  const mobileLinks = document.querySelectorAll(".mobile-menu__link");
  const connectBtn = document.querySelector(".header__button-connect");

  const openMobileMenu = () => {
    mobileMenu.classList.add("mobile-menu--visible");
    document.body.style.overflow = "hidden";
  }
  const closeMobileMenu = () => {
    mobileMenu.classList.remove("mobile-menu--visible");
      document.body.style.overflow = "auto";
  }
  const toggleMobileMenu = () => {
    if (mobileMenu.classList.contains("mobile-menu--visible")) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  }

  burgerBtn.addEventListener("click", toggleMobileMenu);
  connectBtn.addEventListener("click", closeMobileMenu);
  mobileLinks.forEach(link => {
    link.addEventListener("click", closeMobileMenu)
  });

})